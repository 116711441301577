




















































import { Component, Vue } from 'vue-property-decorator';
import { readResponsaveis } from '@/store/responsavel/getters';
import { dispatchDeleteResponsavel, dispatchGetResponsaveis } from '@/store/responsavel/actions';
import { IResponsavel } from '@/interfaces/responsavel';
import ModalConfirmacao from '@/components/ModalConfirmacao.vue';

@Component({
  components: {
    ModalConfirmacao,
  },
})
export default class ListarResponsaveis extends Vue {
  public modalExclusaoAtivo: boolean = false;
  public responsavelExclusao;

  public headers = [
    {
      text: 'ID',
      sortable: true,
      value: 'id',
      align: 'center',
    },
    {
      text: 'Nome',
      sortable: true,
      value: 'nome',
      align: 'left',
    },
    {
      text: 'Sigla',
      sortable: true,
      value: 'sigla',
      align: 'left',
    },
    {
      text: 'Por Localidade?',
      sortable: false,
      value: 'por_localidade',
      align: 'left',
    },
    {
      text: 'Por Ponto de Acesso?',
      sortable: false,
      value: 'por_ponto_acesso',
      align: 'left',
    },
    {
      text: 'Ações',
      align: 'center',
      sortable: false,
    },
  ];

  public options = {
    rowsPerPage: 10,
  };

  get responsaveis() {
    return readResponsaveis(this.$store);
  }

  public abrirModalExclusao(responsavel: IResponsavel) {
    this.responsavelExclusao = responsavel;
    this.modalExclusaoAtivo = true;
  }

  public async confirmarExclusao() {
    const excluiu = await dispatchDeleteResponsavel(this.$store, this.responsavelExclusao);
    await dispatchGetResponsaveis(this.$store);
    this.modalExclusaoAtivo = false;
  }

  public async mounted() {
    await dispatchGetResponsaveis(this.$store);
  }
}
